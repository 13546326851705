<template>
    <Page
        :title="title"
        icon="mdi-checkbox-marked-outline"
        :loading-message="loadingMessage"
        :show-loading="isShowLoading"
        :error-message="errorMessage"
        :show-error="isShowError"
        class-extra="mx-auto"
    >
        <v-tabs
            v-if="folderLines && this.folders"
            :key="JSON.stringify(folders)"
            v-model="tabIndex"
        >
            <v-tab
                v-for="oneFolder in folders"
                :key="oneFolder.folderCode"
                :style="`background-color: #80808020; color: ${oneFolder.folderColor || 'red'};`"
                :value="oneFolder.folderCode"
                class="px-7"
            >
                {{ oneFolder.folderCode }}
                <v-badge
                    :color="oneFolder.folderColor"
                    :content="oneFolder.transactionCount || '0'"
                    v-bind:class="[$store.getters.dark ? 'dark' : '']"
                    offset-x="0"
                    offset-y="0"
                />
            </v-tab>
            <v-tab-item
                v-for="oneFolder in folders"
                :key="oneFolder.folderCode"
                style="min-width: 1200px"
            >
                <smart-table
                    v-if="folderLines && folderLines.length"
                    :headers="headers"
                    :items="folderLines"
                    class="elevation-1"
                    dense
                    @click:row="clickRow"
                    use-click-row-expand
                    hide-default-footer
                    :items-per-page="9999"
                    group-by="groupBy"
                    is-filter
                    :itemsPerPageOptions="[9999]"
                    :filter-text.sync="filterText"
                >
                    <template v-slot:group.header="{ items, isOpen, toggle }">
                        <th colspan="10">
                            <v-icon @click="toggle">
                                {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                            </v-icon>
                            {{ items[0].groupBy }}
                        </th>
                    </template>
                    <template v-slot:expanded-item="{ item }">
                        <td colspan="8">
                            <bill-detail
                                :billApprove="item"
                                :folders="folders"
                                @change="(e) => updatedBillApprove(item.id, e)"
                            />
                        </td>
                    </template>
                    <template v-slot:item.folderCode="{ item }">
                        <v-chip :color="item.folderColor" class="nowrap" outlined>
                            {{ item.folderCode }}
                        </v-chip>
                    </template>
                </smart-table>
            </v-tab-item>
        </v-tabs>
    </Page>
</template>

<script>
import api from '@/api';
import BillDetail from '@/views/accounts/bill/approve/BillDetail';

import objectUtil from '@/../common/utils/object';
import { mapGetters } from 'vuex';

const headers = [
    {
        text: 'Vendor',
        align: 'center',
        sortable: false,
        value: 'vendorCode',
    },
    {
        text: 'Company',
        align: 'left',
        sortable: false,
        value: 'companyLink.text',
    },
    {
        text: 'Date',
        align: 'center',
        sortable: false,
        value: 'documentDate',
    },
    {
        text: 'Due',
        align: 'center',
        sortable: false,
        value: 'dueDate',
    },
    {
        text: 'Reference',
        align: 'left',
        sortable: false,
        value: 'documentReference',
    },
    {
        text: 'Total Amount',
        align: 'right',
        sortable: false,
        value: 'documentTotal',
        customComponent: 'Currency',
    },
    {
        text: 'Balance Owing',
        align: 'right',
        sortable: false,
        value: 'documentBalance',
        customComponent: 'Currency',
    },
    {
        text: 'Approved By',
        align: 'center',
        sortable: false,
        value: 'approvedBy',
    },
    {
        text: 'Folder',
        align: 'center',
        sortable: false,
        value: 'folderCode',
    },
];

export default {
    name: 'APApprove',
    components: { BillDetail },
    data() {
        return {
            loadingMessage: 'Retrieving results.',
            isShowLoading: false,
            errorMessage: '',
            isShowError: false,
            title: 'AP Approve',
            folders: null,
            headers,
            lines: null,
            folderLines: null,
            allData: [],
            expanded: [],
            tabIndex: 0,
            currentFolderCode: 'New',
            filterText: '',
        };
    },
    computed: {
        ...mapGetters(['user']),
    },
    methods: {
        rebuildFolderTitles() {
            console.log('rebuildFolderTitles');
            this.folders = this.folders.map((oneFolder) => {
                const transactionCount = this.filterFolderLines(oneFolder.folderCode, true);

                const folderTitle = `${oneFolder.folderCode} (${transactionCount})`;

                return {
                    ...oneFolder,
                    transactionCount,
                    folderTitle,
                };
            });
        },
        async getLines() {
            this.$store.dispatch('setLoading', 'Retrieving Outstanding Transactions');
            try {
                const result = await api.get(this.$store, 'bill/approve');

                if (result.data) {
                    console.log('AP Outstanding Transactions', result.data);

                    this.folders = [...result.data.folders];

                    this.allData = result.data.transactions.map((oneTransaction) => ({
                        key: `${oneTransaction.companyLink.id} ${oneTransaction.accountsEntityName} ${oneTransaction.accountsId}`,
                        ...oneTransaction,
                    }));

                    this.lines = this.allData.map((oneRecord) => ({
                        id: oneRecord.id,
                        ...oneRecord.billData,
                        approvedBy: objectUtil.getObjectFieldByString(
                            'approved.userLink.text',
                            oneRecord,
                        ),
                        errorMessage: oneRecord.errorMessage,
                        folderCode: oneRecord.folderCode,
                        folderCodes: [oneRecord.folderCode],
                        folderColor: this.getFolderColor(oneRecord.folderCode),
                    }));

                    this.rebuildFolderTitles();

                    this.currentFolderCode = this.folders[this.tabIndex].folderCode;

                    this.filterFolderLines(this.currentFolderCode);
                }
            } catch (e) {
                console.log('Error Loading', e.message, e.stack);
            }

            this.$store.dispatch('setLoading');
        },
        getFolderColor(folderCode) {
            const folder = this.folders
                ? this.folders.find((oneFolder) => oneFolder.folderCode === folderCode)
                : '';
            return folder ? folder.folderColor : '';
        },
        async clickRow(row) {
            console.log('clickRow', { row });

            // this.$router.push(`bill/${row.source}/${row.id}`);
        },
        filterFolderLines(folderCode, isCountOnly = false) {
            const folderLines = this.lines.filter((oneLine) => {
                if (folderCode === 'All') return true;
                if (folderCode === 'New') {
                    return (
                        ['', 'New'].includes(oneLine.folderCode) ||
                        (!isCountOnly &&
                            (oneLine.folderCodes.includes('') ||
                                oneLine.folderCodes.includes('New')))
                    );
                }
                return (
                    oneLine.folderCode === folderCode ||
                    (!isCountOnly && oneLine.folderCodes.includes(folderCode))
                );
            });

            if (isCountOnly) return folderLines.length;
            this.folderLines = folderLines.map((oneFolderLine, lineCount) => {
                oneFolderLine.errorMessage &&
                    console.log(
                        'oneFolderLine',
                        lineCount,
                        oneFolderLine.errorMessage,
                        oneFolderLine,
                    );
                try {
                    return {
                        ...oneFolderLine,
                        groupBy: `${oneFolderLine.vendorCode} - ${
                            oneFolderLine.payment ? oneFolderLine.payment.payee : '???? Payee Error'
                        }`,
                        searchFields: `
                ${oneFolderLine.vendorCode}
                ${oneFolderLine.companyLink && oneFolderLine.companyLink.text}
                ${oneFolderLine.documentDate}
                ${oneFolderLine.dueDate}
                ${oneFolderLine.documentReference}
                $${oneFolderLine.documentTotal}
                $${oneFolderLine.documentBalance}`,
                    };
                } catch (e) {
                    console.log('err', e.message, oneFolderLine);
                }
                return {};
            });
        },
        updatedBillApprove(id, billApprove) {
            console.log('updatedBillApprove', { id, billApprove, lines: this.lines });
            this.lines = this.lines.map((oneLine) =>
                oneLine.id === billApprove.id
                    ? {
                          ...oneLine,
                          approvedBy: billApprove.folderCode === 'Approved' ? this.user.userCode : '',
                          folderCode: billApprove.folderCode,
                          folderColor: this.getFolderColor(billApprove.folderCode),
                          folderCodes: [...oneLine.folderCodes, billApprove.folderCode],
                      }
                    : oneLine,
            );
            this.filterFolderLines(this.currentFolderCode);
            this.rebuildFolderTitles();
        },
    },
    watch: {
        tabIndex() {
            console.log(
                'watch currentFolderCode',
                this.tabIndex,
                this.folders[this.tabIndex].folderCode,
            );
            this.currentFolderCode = this.folders[this.tabIndex].folderCode;
            this.filterFolderLines(this.currentFolderCode);
        },
    },
    async mounted() {
        await this.getLines();
    },
};
</script>
<style>
.v-badge__badge {
    color: black;
}
</style>
